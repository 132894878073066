import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/modules/app-material.module';

@Component({
  selector: 'app-add-button',
  standalone: true,
  imports: [CommonModule, AppMaterialModule],
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss'],
})
export class AddButtonComponent {
  @Input() disabled = false;
}
