<button
  [disabled]="disabled"
  mat-raised-button
  class="w-100 mat-elevation-z4 button"
>
  <div class="button__icon">
    <mat-icon>add</mat-icon>
  </div>
  <ng-content></ng-content>
</button>
